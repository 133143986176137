import { useLanguage } from '@/hooks/Contexts'
import { API_URL } from '@/utils/Consts'
import { svgs } from '@/utils/Images'
import { appUtils } from 'lib/AppUtils'
import axios from 'lib/axios'
import { useRouter } from 'next/router'
import { FC, useEffect, useState } from 'react'
import { ModalContainer } from './Modal'

export const Notification: FC = () => {
  const { orders: orderStr, navigation: navStr, actions: actStr } = useLanguage()
  const { notify_prize_descr: descrStr } = orderStr
  const router = useRouter()

  const [loading, setLoading] = useState<boolean>(false)
  const [messages, setMessages] = useState<Array<Record<string, any>>>([])
  const [showIndex, setShowIndex] = useState<number>(0)
  const [show, setShow] = useState<boolean>(false)
  const [showTooltip, setShowTooltip] = useState<boolean>(false)

  useEffect(() => {
    if (!loading) {
      setLoading(true)
      appUtils
        .fetcher(API_URL.receiveNotif, false)
        .then(data => {
          setMessages(data?.messages || [])
          if (data?.messages?.length > 0) {
            sendReadNotify(data.messages[0].id)
            setShow(true)
          }
        })
        .catch(() => {})
        .finally(() => setLoading(false))
    }
  }, [])

  const sendReadNotify = (id: number | Array<number>) => {
    let ids = []
    // filter out read msg
    if (Array.isArray(id)) {
      ids = id.filter(x => !messages.find(y => y.id == x)?.['hasRead'])
    } else if (!messages.find(y => y.id == id)?.['hasRead']) {
      ids.push(id)
    }
    if (ids.length > 0) {
      axios
        .post(API_URL.readNotif, { ids })
        .then(res => {
          if (res?.status === 200) {
            // mark read state
            setMessages(prev => {
              const arr = [...prev]
              if (Array.isArray(id)) {
                id.forEach(x => {
                  const i = arr.findIndex(y => y.id == x)
                  if (i > -1) {
                    arr[i]['hasRead'] = true
                  }
                })
              } else {
                const i = arr.findIndex(y => y.id == id)
                if (i > -1) {
                  arr[i]['hasRead'] = true
                }
              }
              return arr
            })
          }
        })
        .catch(() => {})
    }
  }

  const onClickNextMessage = () => {
    if (messages.length > 0) {
      const num = showIndex + 1
      if (messages.length > num) {
        sendReadNotify(messages[num]?.id)
        setShowIndex(num)
      } else if (messages.length == num) {
        setShow(false)
      }
    }
  }

  const onClickPreviousMessage = () => {
    if (messages.length > 0 && showIndex > 0) {
      setShowIndex(prev => prev - 1)
    }
  }

  const onCLickReadAll = () => {
    const tmp = []
    messages.forEach(v => tmp.push(v.id))
    sendReadNotify(tmp)
    setShow(false)
  }

  return (
    <>
      {messages.length > 0 && (
        <ModalContainer
          show={show}
          onClose={() => setShow(false)}
          // @ts-ignore
          innerProps={{ 'className': 'relative max-w-lg', 'data-testid': 'div-istWin-popUp' }}
          bodyCls='relative flex w-full flex-col gap-y-4 text-center'
        >
          <h2 className='text-xl font-semibold md:text-2xl'>{descrStr[2]}</h2>
          <p className='text-center text-sm md:text-base'>{descrStr[3]}</p>
          <h1 className='text-2xl font-bold md:text-3xl' data-testid='txt-istWinPop-priz'>
            {messages[showIndex]?.content?.prize_content}
          </h1>

          <div className='flex items-center justify-between text-sm font-semibold md:text-base'>
            <button aria-label='Previous Message' className='flex items-center text-cyan-v2 hover:text-amber-v1' onClick={onClickPreviousMessage}>
              <svg xmlns='http://www.w3.org/2000/svg' className='h-5 w-5' viewBox='-4 0 18 18' fill='currentColor'>
                {svgs.arrow_prev}
              </svg>
              {actStr.prev}
            </button>

            <div
              className='relative flex w-full max-w-[140px] cursor-pointer items-center justify-center'
              onClick={onCLickReadAll}
              onMouseOver={() => setShowTooltip(true)}
              onMouseLeave={() => setShowTooltip(false)}
            >
              <span data-testid='txt-istWinPop-prizNum'>
                {showIndex + 1}/{messages.length}
              </span>
              <span className='ml-2 h-6 w-6 md:h-7 md:w-7'>{svgs.envelop}</span>
              {/* tooltip */}
              <div className={`${showTooltip ? 'visible' : 'hidden'} absolute -top-12 left-0 z-20 flex w-full justify-center text-center`}>
                <span
                  className={`rounded-lg bg-stone-900/70 p-2 text-center text-sm text-white/100 after:absolute after:left-1/2 after:top-full after:border-8 after:border-transparent after:border-t-stone-900/70 after:content-['']`}
                >
                  {orderStr.read_all_tip}
                </span>
              </div>
            </div>

            <button aria-label='Next Message' className='flex items-center text-cyan-v2 hover:text-amber-v1' onClick={onClickNextMessage}>
              {actStr.next}
              <svg xmlns='http://www.w3.org/2000/svg' className='h-5 w-5' viewBox='-4 0 18 18' fill='currentColor'>
                {svgs.arrow_next}
              </svg>
            </button>
          </div>

          <div className='border-t border-t-stone-900/70 pt-4 text-sm md:text-base'>
            {descrStr[{ credit: 4, cash: 5, product: 6 }[messages[showIndex]?.content?.templateType]]}
          </div>
          <div className='flex w-full items-stretch justify-between gap-3'>
            {[
              { link: messages[showIndex]?.content?.btn_cancel, text: navStr.my_account },
              { link: messages[showIndex]?.content?.btn_confirm, text: navStr.competitions },
            ].map(
              (item, index) =>
                item.link && (
                  <button
                    key={index}
                    className={`btn-hover-amber h-10 w-full font-semibold capitalize md:px-4 ${index == 0 ? 'btn-rd-outline' : 'btn-rd-amber'}`}
                    onClick={() => {
                      router.push(item.link)
                      setShow(false)
                    }}
                  >
                    {item.text}
                  </button>
                )
            )}
          </div>
        </ModalContainer>
      )}
    </>
  )
}
export default Notification
